import Vue from 'vue'
import Router from 'vue-router'
import GSignInButton from 'vue-google-signin-button'
import Notifications from 'vue-notification'
Vue.use(Router)
Vue.use(GSignInButton)
Vue.use(Notifications)
export default new Router({
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Dashboard.vue')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ '../components/login/Login.vue')
    }
  ]
});