<template>
  <div class="sidebar-container">
  <menu-icon class="menu-icon" fillColor="#bfc5d2" :size="24" />
  <ul>
    <li v-on:click="switchTab('dashboard')" v-bind:class="{active: this.activeTab === 'dashboard'}"><poll-box :fillColor="this.activeTab === 'dashboard' ? '#008dd0' : '#bfc5d2'"  :size="24" /></li>
    <li v-on:click="switchTab('settings')" v-bind:class="{active: this.activeTab === 'settings'}"><settings :fillColor="this.activeTab === 'settings' ? '#008dd0' : '#bfc5d2'"  :size="24" /></li>
  </ul>
  </div>
</template>

<script>
import MenuIcon from "vue-material-design-icons/Menu.vue"
import PollBox from "vue-material-design-icons/PollBox.vue"
import Settings from "vue-material-design-icons/Settings.vue"

export default {
  components: {
    MenuIcon,
    PollBox,
    Settings
  },
  name: 'sidebar',
  methods: {
    switchTab (tab) {
      this.activeTab = tab
    }
  },
  data() {
    return {
      activeTab: 'dashboard',
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-container {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-top: 33px;
  padding-bottom: 33px;
  width: 80px;
  height: 100vh;
  flex-direction: column;
  border-right: 1px solid #e4e8f0;
  .menu-icon {
    padding-bottom: 32px;
  }
  ul {
    width: 80px;
    li {
      cursor: pointer;
      height: 56px;
      transition: background 0.3s ease;
      box-sizing: border-box;
      padding: 16px 28px 16px 28px;
    }
    .active {
        padding: 16px 28px 16px 25px;
        background: rgba(0, 141, 208, 0.1);
        border-left: 3px solid #008dd0;
      }
  }
}

</style>
