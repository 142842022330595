<template>
  <div class="navbar-container">
    <img src="../../assets/logotype.svg" alt="logo" />
    <div class="user">
      <span>NSofter</span>
      <img src="https://pbs.twimg.com/profile_images/1102900259104141313/ysHZEzUV_400x400.png" alt="user">
    </div>
  </div>
</template>

<script>
export default {
  name: 'navbar'
}
</script>

<style lang="scss" scoped>
.navbar-container {
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  width: calc(100% - 80px);
  height: 80px;
  align-items: center;
  margin-left: 80px;
  border-bottom: 1px solid #e4e8f0;
  justify-content: space-between;
  img {
    width: 100px;
    height: 31px;
    margin-left: 32px;
  }
  .user {
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
      margin-left: 12px;
      border-radius: 16px;
    }
    span {
      font-family: Rubik;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: normal;
      color: #8798ad;

    }
  }
}
</style>
