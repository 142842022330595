import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiEnabled: true,
    filtersOpened: true,
    dashboardTab: "table",
    companies: [],
    query: `{}`,
    chartDevicesData: [],
    chartDevicesCount: 0,
    tableDevicesData: [],
    tablePage: 1,
    tablePaginationData: null,
    token: localStorage.getItem('token') || null
  },
  mutations: {
    updateFiltersOpened: (state, payload) => {
      state.filtersOpened = payload;
    },
    updateDashboardTab: (state, payload) => {
      state.dashboardTab = payload;
    },
    updateCompanies: (state, payload) => {
      state.companies = payload;
    },
    updateQuery: (state, payload) => {
      state.query = payload;
    },
    updateChartDevicesData: (state, payload) => {
      state.chartDevicesData = payload;
    },
    updateChartDevicesCount: (state, payload) => {
      state.chartDevicesCount = payload;
    },
    updateTableDevicesData: (state, payload) => {
      state.tableDevicesData = payload;
    },
    updateTablePage: (state, payload) => {
      state.tablePage = payload;
    },
    updatePaginationData: (state, payload) => {
      state.tablePaginationData = payload;
    }
  },
  getters: {
    filtersOpened(state) {
      return state.filtersOpened;
    },
    chartDevicesData(state) {
      return state.chartDevicesData;
    },
    chartDevicesCount(state) {
      return state.chartDevicesCount;
    },
    tableDevicesData(state) {
      return state.tableDevicesData;
    },
    tablePage(state) {
      return state.tablePage;
    },
    dashboardTab(state) {
      return state.dashboardTab;
    },
    companies(state) {
      return state.companies;
    },
    tablePaginationData(state) {
      return state.tablePaginationData;
    },
    query(state) {
      return state.query;
    }
  }
});
